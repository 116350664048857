import React, { useState } from "react";
import { Icon } from "@iconify/react";
import jsIcon from "@iconify/icons-logos/javascript";
import reactIcon from "@iconify/icons-logos/react";
import vueIcon from "@iconify/icons-logos/vue";
import fileIcon from "@iconify/icons-mdi/file-document";

function About({ sharedBasicInfo, resumeBasicInfo }) {
  const [profilepic] = useState(
    sharedBasicInfo ? "images/" + sharedBasicInfo?.image : ""
  );

  const [sectionName] = useState(resumeBasicInfo?.section_name?.about);
  const [hello] = useState(resumeBasicInfo?.description_header);
  const [about] = useState(resumeBasicInfo?.description);

  return (
    <section id="about">
      <div className="col-md-12">
        <h1 style={{ color: "black" }}>
          <span>{sectionName}</span>
        </h1>
        <div className="row center mx-auto mb-5">
          <div className="col-md-4 mb-5 center">
            <div className="polaroid">
              <span style={{ cursor: "auto" }}>
                <img src={profilepic} alt="Avatar placeholder" />
                <Icon
                  icon={jsIcon}
                  style={{ fontSize: "400%", margin: "9% 5% 0 5%" }}
                />
                <Icon
                  icon={reactIcon}
                  style={{ fontSize: "400%", margin: "9% 5% 0 5%" }}
                />
                <Icon
                  icon={vueIcon}
                  style={{ fontSize: "400%", margin: "9% 5% 0 5%" }}
                />
              </span>
            </div>
          </div>

          <div className="col-md-8 center">
            <div className="col-md-10">
              <div className="card">
                <div className="card-header">
                  <span
                    className="iconify"
                    data-icon="emojione:red-circle"
                    data-inline="false"
                  ></span>{" "}
                  &nbsp;{" "}
                  <span
                    className="iconify"
                    data-icon="twemoji:yellow-circle"
                    data-inline="false"
                  ></span>{" "}
                  &nbsp;{" "}
                  <span
                    className="iconify"
                    data-icon="twemoji:green-circle"
                    data-inline="false"
                  ></span>
                </div>
                <div
                  className="card-body font-trebuchet text-justify ml-3 mr-3"
                  style={{
                    height: "auto",
                    fontSize: "132%",
                    lineHeight: "200%",
                  }}
                >
                  <br />
                  {/* <span className="wave">{hello} :) </span> */}
                  {/* <span className="wave">{hello} 🙂 </span> */}
                  <span className="wave">{hello} 👋 </span>
                  <br />
                  <br />
                  {about}
                  <br />
                  {/* <a
                    href="documents/Bernard_Resume.pdf"
                    className="text-decoration-none"
                    download
                  >
                    <button
                      className="btn btn-primary mt-2 d-flex"
                      style={{ fontSize: "1.25rem" }}
                    >
                      <Icon
                        icon={fileIcon}
                        style={{ fontSize: "130%" }}
                        className="mr-2"
                      />
                      Resume
                    </button>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
